    import React, { useEffect, useState, useRef } from "react";
import styled from "@emotion/styled";
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import search from '../../assets/icons/search.svg';
import Stack from '@mui/material/Stack';
import QRCode from "react-qr-code";
import * as htmlToImage from "html-to-image";
    import { toast } from "react-toastify";
import { json } from "react-router-dom";
import { loading } from "../../Loading";
// import Width from '../../components/Width'
import useWindowSize from "../../components/Width";
import API from "../../API_CONFIGS/redux/API"; 

import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import TableHead from '@mui/material/TableHead';


const Root = styled('div')({ 
    width:'100vw',
    display:'flex',
    flexDirection:'column',
    margin:'auto',
    justifyContent:'center'
    
})
const Header = styled('div')({
    // width:'100%',
    display:'flex',
    flexDirection:'column',
    alignItems:'center',
    justifyContent:'center',
    fontSize:'15pt',
    fontWeight:'bold',
    padding:'10px',
    textAlign:'center',

})
const Body = styled('div')({
    // width:'100%',
    display:'flex',
    gap:'20px',
    padding:'20px',
    justifyContent:'center',
    alignItems:'center',

    flexDirection:'column',

})
const Results = styled('div')({
    maxWidth:'80vw',
    display:'flex',
    // gap:'30px',
    // padding:'20px',
    justifyContent:'center',
    // alignItems:'center',
    flexDirection:'column',

    // overflow:'auto',

    // backgroundColor:'red'

})
const ShowDoc_Desktop = styled('div')({
    display:'flex', 
    flexDirection:'column',
    gap:'10px',
    padding:'20px',
    maxWidth:'500px',
    justifyContent:'center',
    // alignItems:'center',
    // backgroundColor:'red',

    border:'1px solid #000c7c',
    borderRadius:'5px',


})
const SingleInformation = styled('div')({
    display:'flex', 
    // gap:'10px',
    // width:'20%',
    fontSize:'12pt',
    fontWeight:'bold',
    // margin:'0px 20px 0px 0px'



})


function showDate(dateString){
        const day = new Date(dateString).getDate()
        const month = new Date(dateString).getMonth() + 1
        const year = new Date(dateString).getFullYear()
        return `${day}/${month}/${year}`
}

function showCpf(string){
    var aux = string
    aux = aux.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "\$1.\$2.\$3-\$4")
    return aux
}






function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;
  
    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };
  
    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };
  
    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };
  
    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
  
    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  }
  
  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
  };
  
  function createData(name, calories, fat) {
    return { name, calories, fat };
  }
  

  const columns = [
    { id: 'name', label: 'Nome', minWidth: 160 },
    { id: 'cpf', label: 'CPF', minWidth: 160 },
    { id: 'coursetitle', label: 'Curso', minWidth: 160 },
    { id: 'workload', label: 'Carga horária', minWidth: 160 },
    { id: 'code', label: 'Código', minWidth: 160 },
    { id: 'id', label: 'QR Code', minWidth: 100 },
  ];
  







export default function (){

    const {width} = useWindowSize()

    const [docID,setdocID]=useState('')
    const [showResult,setshowResult]=useState(false)
    const [blocksearch,setblocksearch]=useState(false)
    const [alldocs,setalldocs] = useState([
        {
            id:'abc',
            aluno:'aluno nome 1',
            documento_nome:'Curso de abc',
            carga_horaria:123,
            tipo:'curso',
            data_emissão:'10/12/2016'
        },
    ])
    const [docs,setDocs] = useState({})


    
    
    const qrCodeContainerRef = useRef(null);
    


    useEffect(()=>{
        const path = window.location.pathname.split('/')
        if (path[2] && !showResult && !docID ) {
            setdocID(path[2])
            setblocksearch(true)
        }
        else if(window.location.pathname !== '/validar'){
            // handleSearch()
        }

    },[docID])


    const handleDownload  = () => {
        if( docID ){


            if (qrCodeContainerRef.current) {
                console.log(qrCodeContainerRef);
                const container = qrCodeContainerRef.current;
          
                // Use htmlToImage to capture the container as an image
                htmlToImage
                  .toPng(container)
                  .then(function (dataUrl) {
                    // Create a downloadable link
                    const downloadLink = document.createElement("a");
                    downloadLink.href = dataUrl;
                    downloadLink.download = `${docID}.png`;
                    return downloadLink.click();
                  })
                  .catch(function (error) {
                    return console.error("Error generating QR code image", error);
                  });
              }
                                                    
            
            }
        return
      };
      







      const [rows, setRows] = useState([])

      const [page, setPage] = React.useState(0);
      const [count, setCount] = React.useState(0);
      const rowsPerPage = 10
    
      // Avoid a layout jump when reaching the last page with empty rows.
      const emptyRows =
        page > 0 && rows ? Math.max(0, (1 + page) * rowsPerPage - (rows?.length || 0)) : 0;
    
      const handleChangePage = (event, newPage) => {
        setPage(newPage);
      };
    

      



      useEffect(()=>{
  
        loading(true);

          API.get(`/administrativo/listAllCertificates/${page}`)
          .then((res)=>{

            console.log(res?.data?.certificates);

              toast.success('Dados encontrados')
              setblocksearch(true)
              loading(false);
              setRows(res?.data?.certificates || [])
              setCount(res?.data?.count || 0)
              return setshowResult(true)        
          })
          .catch((error)=>{
              loading(false);
              toast.error('Nenhum dado encontrado')
              return setshowResult(false)        
          })
  
  
  
  
      },[page])
  




      

    return(
        <Root   style={{    }}   >
            <Header >
                Listar todos os certificados já emitidos
            </Header>

            <Body >




<TableContainer component={Paper}>
      <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">

      <TableHead>
            <TableRow>
              {columns?.map((column) => (
                <TableCell
                  key={column.id}
                //   align={column.align}
                //   style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

        <TableBody>
          {rows?.map((row) => (
            <TableRow key={row.id}>
              <TableCell style={{ width: 160 }} >
                {row?.name || "-"}
              </TableCell>
              <TableCell style={{ width: 160 }} >
                {showCpf(row?.cpf) || "-"}
              </TableCell>
              <TableCell style={{ width: 160 }} >
                {row?.coursetitle || "-"}
              </TableCell>
              <TableCell style={{ width: 160 }} >
                {row?.workload || "-"}
              </TableCell>
              <TableCell style={{ width: 160 }} >
                {row?.code || "-"}
              </TableCell>
              <TableCell style={{ width: 100 }} >
                <button onClick={()=>{window.open(`/validar/${row?.code}`, '_blank')}
                    } style={{backgroundColor:'#000c7c', color:'#fff', padding:'5px', borderRadius:'5px', cursor:'pointer'}}
                >Verificar</button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[10]}
              colSpan={3}
              count={count}
              rowsPerPage={10}
              page={page}
              onPageChange={handleChangePage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>




            </Body>

        </Root>
    )
}