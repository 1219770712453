import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { styled } from "@mui/material";
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from "dayjs";
import { createTheme } from '@mui/material/styles'
import useWindowSize from "../../components/Width";
import { loading } from "../../Loading";
import { toast } from "react-toastify";
import * as htmlToImage from "html-to-image";
import Button from '@mui/material/Button';
import search from '../../assets/icons/search.svg';
import QRCode from "react-qr-code";
import API from "../../API_CONFIGS/redux/API";
import { useFileUpload } from 'use-file-upload'




const Root = styled('div')({
    width:'100%',
    display:'flex',
    flexDirection:'column',

})
const Header = styled('div')({
    width:'100%',
    display:'flex',
    gap:'30px',
    alignItems:'center',
    justifyContent:'center',


})
const Header_option = styled('div')({
    // width:'100%',
    display:'flex',
    flexDirection:'column',
    fontSize:'12pt',
    color:'rgba(141, 140, 141, 1)',
    cursor:'pointer',
    margin:'20px 0px 20px 0px'



})

const Body = styled('div')({
    width:'90%',
    display:'flex',
    flexDirection:'column',
    justifyContent:'center',
    alignItems:'center',
    padding:'20px',
    gap:'20px',
    margin:'auto'
    // fontSize:'12pt',
    // color:'rgba(141, 140, 141, 1)',
    // cursor:'pointer',
    // margin:'20px 0px 20px 0px'



})
const Body_1 = styled('div')({
    width:'100%',
    display:'flex',
    flexWrap:'wrap',
    // flexDirection:'column',
    // justifyContent:'center',
    alignItems:'center',
    gap:'30px',
    padding:'20px',
    margin:'auto',


})
const SingleInput = styled('div')({
    width:'30%',
    minHeight:'100px',
    display:'flex',
    flexDirection:'column', 
    gap:'10px',
    

})

const Body_2 = styled('div')({
    width:'100%',
    display:'flex',
    flexDirection:'column',
    // fontSize:'12pt',
    // color:'rgba(141, 140, 141, 1)',
    // cursor:'pointer',
    // margin:'20px 0px 20px 0px'



})

const Results = styled('div')({
    maxWidth:'80vw',
    display:'flex',
    // gap:'30px',
    // padding:'20px',
    justifyContent:'center',
    // alignItems:'center',
    flexDirection:'column',

    // overflow:'auto',

    // backgroundColor:'red'

})
const ShowDoc_Desktop = styled('div')({
    display:'flex', 
    flexDirection:'column',
    gap:'10px',
    padding:'20px',
    maxWidth:'500px',
    justifyContent:'center',
    alignItems:'center',
    // backgroundColor:'red',

    border:'1px solid #000c7c',
    borderRadius:'5px',


})
const SingleInformation = styled('div')({
    display:'flex', 
    // flexDirection:'column',
    gap:'10px',
    // padding:'20px',
    // width:'50%',
    // justifyContent:'center',
    alignItems:'center',
    // backgroundColor:'red',

    // border:'1px solid #000c7c',
    // borderRadius:'5px',

    fontSize:'12pt',
    fontWeight:'bold',



})

const ButtonStyles = styled('div')({
    width:'100px',
    margin:'auto',
    backgroundColor: 'white',
    padding: '5px 10px 5px 10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    borderRadius: '5px',
    transition: 'background-color 0.3s ease', 
    backgroundColor:'#000c7c',
    color:'white',
  
    ':hover': {
        transform: 'scale(1.05)',
        fontWeight: 'bold',
    },
  
})


export default function(){
    const navigate = useNavigate()
    const {width} = useWindowSize()

    const currentUser =  useSelector((state) => state.auth.user);

    const [isactive,setisactive]=useState(1)
    
    const [docType,setdocType]=useState('')
    const [docType_id,setdocType_id]=useState('')
    
    const [allevents,setallevents]=useState([])
    const [allcolleges,setallcolleges]=useState([])
    const [college_id,setcollege_id]=useState('')
    const [college,setcollege]=useState('')

    const [nocustomdate,setnocustomdate]=useState(true)
    const [dateError,setdateError]=useState(false)
    const [docDate,setdocDate]=useState(dayjs(new Date()))
    
    const [additionalInfo,setadditionalInfo]=useState('Teste teste Teste teste Teste teste Teste teste Teste teste Teste teste Teste teste Teste teste Teste teste Teste teste Teste teste Teste teste Teste teste Teste teste Teste teste Teste teste Teste teste Teste teste Teste teste Teste teste Teste teste Teste teste Teste teste Teste teste Teste teste Teste teste Teste teste Teste teste Teste teste Teste teste Teste teste Teste teste Teste teste Teste teste Teste teste Teste teste Teste teste Teste teste Teste teste Teste teste Teste teste Teste teste Teste teste Teste teste Teste teste Teste teste Teste teste Teste teste Teste teste Teste teste Teste teste Teste teste Teste teste Teste teste ')
    
    const [studentName,setstudentName]=useState('')
    const [cpf,setcpf]=useState('')
    const [coursetitle,setcoursetitle]=useState('')
    const [courseprogram,setcourseprogram]=useState('')
    const [workload,setworkload]=useState('')
    const [imageqrcode,setimageqrcode]=useState('')
    const [imagecertificate,setimagecertificate]=useState('')
    
     
    const [docID,setdocID]=useState('')
    const [showResult,setshowResult]=useState(false)
    const [blocksearch,setblocksearch]=useState(false)
    const [alldocs,setalldocs] = useState([
        {
            id:'abc',
            aluno:'aluno nome 1',
            documento_nome:'Curso de abc',
            carga_horaria:123,
            tipo:'curso',
            data_emissão:'10/12/2016'
        },
        {
            id:'hm89H)*m8u9',
            aluno:'aluno nome 2',
            documento_nome:'Curso de abc',
            carga_horaria:123,
            tipo:'curso',
            data_emissão:'10/12/2016'
        },
        {
            id:'dfvr%&D$5b56&$d',
            aluno:'aluno nome 3',
            documento_nome:'Curso de abc',
            carga_horaria:123,
            tipo:'curso',
            data_emissão:'10/12/2016'
        },
        {
            id:'FBG%&gnm768yG¨&*',
            aluno:'aluno nome 4',
            documento_nome:'Curso de abc',
            carga_horaria:123,
            tipo:'curso',
            data_emissão:'10/12/2016'
        },
    ])
    const [docs,setDocs] = useState([])
    
    const qrCodeContainerRef = useRef(null);


    const [eventname,seteventname]=useState('')
    const [eventlocation,seteventlocation]=useState('')
    const [eventtype_id,seteventtype_id]=useState('')
    const [eventtype_name,seteventtype_name]=useState('')
    const [alleventType,setalleventType]=useState([])

    const [file, selectFile] = useFileUpload()
    const [isfileok, setisfileok] = useState(false)





    const getallUpdatedData = ()=>{
        API.post('/administrativo/getdoctypes')
        .then((res)=>{
            setallevents(res.data.event_types)
        })
        .catch((error)=>{
            toast.error('erro buscando tipos de evento')
            console.log(error);
        })
        API.post('/administrativo/getcolleges')
        .then((res)=>{
            setallcolleges(res.data.college)
        })
        .catch((error)=>{
            toast.error('erro buscando instituições')
            console.log(error);
        }) 
        API.post('/administrativo/geteventtypes')
        .then((res)=>{
            setalleventType(res.data.event_types)
        })
        .catch((error)=>{
            toast.error('erro buscando tipos de evento')
            console.log(error);
        })
    }
  
    useEffect(()=>{
        getallUpdatedData()
    },[]) 
    useEffect(()=>{
        if(!currentUser){
            navigate('/login')
        }
    },[currentUser])
    useEffect(()=>{
        if(nocustomdate){
            return setdocDate(dayjs(new Date()))
        }
    },[nocustomdate])
    useEffect(()=>{
        const path = window.location.pathname.split('/')
        if (path[2] && !showResult && !docID ) {
            setisactive(2)
            setdocID(path[2])
            setblocksearch(true)
        }
        else if(window.location.pathname !== '/administrativo'){
            handleSearch()
        }

    },[docID])





    const sendInfo = () => {

        const data = {
            docType_id:docType_id,
            college_id:college_id,
            studentName:studentName,
            user_id:currentUser.id,
            docDate:docDate,
            cpf:cpf,
            coursetitle:coursetitle,
            courseprogram:courseprogram,
            workload:workload,
            imagecertificate:imagecertificate,
            imageqrcode:imageqrcode,
        }

        API.post('/administrativo/newcertificate',data)
        .then((res)=>{
            toast.success('Certificado criado')

        })
        .catch((error)=>{
            toast.error('Erro criando certificado')
        })


    }
    const createnewevent = () => {

        const data = {
            eventtype_id:eventtype_id,
            user_id:currentUser.id,
            eventlocation:eventlocation,
            eventname:eventname,
        }

        API.post('/administrativo/newevent',data)
        .then((res)=>{
            toast.success('Evento criado')

        })
        .catch((error)=>{
            toast.error('Erro criando Evento')
        })


    }

    const handledate = (e)=>{
        
        // console.log(e);

        if(!e.$d){
            setdateError(false)
            return setdocDate(new Date())
        }


        const currentDate = new Date()
        const minDate = new Date('01-01-2017')
        const selectedDate = e.$d
        setdocDate(dayjs(selectedDate))

        if(isNaN((selectedDate))){
            return setdateError(true)
        }
        
        console.log(`selectedDate = ${selectedDate}`);
        console.log(`dayjs(selectedDate) = ${dayjs(selectedDate)}`);

        if((currentDate.getTime() < selectedDate.getTime()) || (minDate.getTime() > selectedDate.getTime()) ){
            console.log(minDate.getTime() > selectedDate.getTime());
            return setdateError(true)
        }
        return setdateError(false)

    }   

    const handleSearch = ()=>{
        loading(true);
        setshowResult(false)

        const path = window.location.pathname.split('/')

        if(isactive===2){
            if((!docID ) && (!path[2] ) && window.location.pathname === '/administrativo' ){
                loading(false)
                return toast.info('Preencha os dados para buscar')
            }


                    const aux = alldocs.filter((singleDoc) =>singleDoc.id === docID);
                        if(aux.length>0){
                            setDocs(aux)
                            toast.success('Dados encontrados')
                            setblocksearch(true)
                            loading(false);
                            return setshowResult(true)        
                        }
            loading(false);
            toast.error('Nenhum dado encontrado')
        }
            return setshowResult(false)        
    }


    const handleDownload  = () => {
        if( docID ){


            if (qrCodeContainerRef.current) {
                console.log(qrCodeContainerRef);
                const container = qrCodeContainerRef.current;
          
                // Use htmlToImage to capture the container as an image
                htmlToImage
                  .toPng(container)
                  .then(function (dataUrl) {
                    // Create a downloadable link
                    const downloadLink = document.createElement("a");
                    downloadLink.href = dataUrl;
                    downloadLink.download = `qrcode.png`;
                    return downloadLink.click();
                  })
                  .catch(function (error) {
                    return console.error("Error generating QR code image", error);
                  });
              }
                                                    
            
            }
        return
      };
      


    return(
        <Root>

            <Header>
                <Header_option style={isactive===1?{color:'#000c7c', fontSize:'14pt',fontWeight:'bold' }:{}} onClick={()=>{getallUpdatedData(); return setisactive(1)}}  >Certificado Único {isactive===1 &&<div  style={{width:'100%', height:'1px', backgroundColor:'#000c7c'}}  />} </Header_option>
                <Header_option style={isactive===2?{color:'#000c7c', fontSize:'14pt',fontWeight:'bold' }:{}} onClick={()=>{getallUpdatedData(); return setisactive(2)}}  >Certificado Em Lote {isactive===2 &&<div  style={{width:'100%', height:'1px', backgroundColor:'#000c7c'}}  />} </Header_option>
                <Header_option style={isactive===3?{color:'#000c7c', fontSize:'14pt',fontWeight:'bold' }:{}} onClick={()=>{getallUpdatedData(); return setisactive(3)}}  >Evento {isactive===3 &&<div  style={{width:'100%', height:'1px', backgroundColor:'#000c7c'}}  />} </Header_option>
            </Header>   

            <div style={{width:'100%', height:'1px', backgroundColor:'#ccc'}}/>

        
            <Body>

                {isactive===1 &&
                
                    <Body_1>
                        
                        <SingleInput>
                            <InputLabel >Evento</InputLabel>
                            <FormControl sx={{width:'300px'}}>
                                <InputLabel >Evento</InputLabel>
                                <Select
                                // labelId="demo-simple-select-label"
                                // id="demo-simple-select"
                                value={docType}
                                label="Evento"
                                onChange={(e)=>{
                                    console.log(e.target.value);
                                    setdocType(e.target.value); 

                                    var thisdoc = allevents.find(single=>single.id===e.target.value) 
                                    return setdocType_id(thisdoc.id)
                                }}
                                >
                                    {allevents && allevents.map((single,index)=>{
                                        if(single.id && single.eventname){
                                            return <MenuItem value={single.id} key={index}>{single.eventname}</MenuItem>
                                        }
                                        return null
                                    }).filter(Boolean)}
                                </Select>
                            </FormControl>
                        </SingleInput>

                        <SingleInput>
                            <InputLabel >Instituição</InputLabel>
                            <FormControl sx={{width:'300px'}}>
                                <InputLabel >Instituição</InputLabel>
                                <Select
                                // labelId="demo-simple-select-label"
                                // id="demo-simple-select"
                                value={college}
                                label="Instituição"
                                onChange={(e)=>{
                                    console.log(e.target.value);
                                    setcollege(e.target.value); 

                                    var thisdoc = allcolleges.find(single=>single.id===e.target.value) 
                                    if (thisdoc) {
                                        return setcollege_id(thisdoc.id)
                                    }
                                }}
                                >
                                    {allcolleges && allcolleges.map((single,index)=>{
                                        if(single.id && single.name){
                                            return <MenuItem value={single.id} key={index}>{single.name}</MenuItem>
                                        }
                                        return null
                                    }).filter(Boolean)}
                                </Select>
                            </FormControl>
                        </SingleInput>


                        <SingleInput >
                            <InputLabel >Nome do titular</InputLabel>
                            <TextField label="Nome do titular" variant="outlined" sx={{width:'300px'}} value={studentName} onChange={(e)=>{return setstudentName(e.target.value)}}/>
                        </SingleInput>

                        <SingleInput >
                            <InputLabel >CPF</InputLabel>
                            <TextField label="CPF do titular" variant="outlined" sx={{width:'300px'}} value={cpf} onChange={(e)=>{return setcpf(e.target.value)}}/>
                        </SingleInput>

                        <SingleInput >
                            <InputLabel >Nome do curso</InputLabel>
                            <TextField label="Nome do curso" variant="outlined" sx={{width:'300px'}} value={coursetitle} onChange={(e)=>{return setcoursetitle(e.target.value)}}/>
                        </SingleInput>


                        <SingleInput >
                            <InputLabel >Carga horária</InputLabel>
                            <TextField label="Carga horária" variant="outlined" sx={{width:'300px'}} value={workload} onChange={(e)=>{return setworkload(e.target.value)}}/>
                        </SingleInput>

                        <SingleInput >
                            <InputLabel >Data de Emissão</InputLabel>
                            <FormGroup style={{display:'flex', flexDirection:'row', gap:'10px', alignItems:'center', }}>
                                <FormControlLabel control={<Switch checked={nocustomdate} />} label={nocustomdate?'Atual':'Personalizada'} onChange={()=>{setnocustomdate(!nocustomdate)}}/>
                                    {
                                        nocustomdate &&
                                        <p>( {`${new Date(docDate).getDate()} / ${new Date(docDate).getMonth()+1} / ${new Date(docDate).getFullYear()}`} )</p>
                                    }
                            </FormGroup>

                                {!nocustomdate &&
                                <>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        {/* <DemoContainer components={['DatePicker']}> */}
                                            <DatePicker label="DD/MM/AAAA" disableFuture  onChange={handledate} value={docDate}  
                                                format="DD/MM/YYYY"
                                                
                                                    sx={
                                                        dateError?
                                                    
                                                    {  
                                                        
                                                        '& .MuiOutlinedInput-input': {
                                                            color: 'red !important',
                                                    },
                                                        '& .MuiFormLabel-root': {
                                                            color: 'red !important',
                                                    },
                                                        '& .MuiOutlinedInput-notchedOutline': {
                                                            color: 'red !important',
                                                            borderColor: 'red !important',
                                                            // backgroundColor:'red !important',
                                                            // border:'1px solid red  !important',

                                                    },
                                              }:{}}


                                                />
                                        {/* </DemoContainer> */}
                                    </LocalizationProvider>
                                    {
                                        dateError &&
                                        <p style={{color:'red', fontSize:'10pt', }}>Data inválida. Verificar.</p>
                                    }
                                </>
                                }
 
                        </SingleInput> 


                        <SingleInput >
                            <InputLabel >Programa do curso</InputLabel>
                            {/* <TextField label="Programa do curso" variant="outlined" sx={{width:'300px'}} value={courseprogram} onChange={(e)=>{return setcourseprogram(e.target.value)}}/> */}
                            <textarea  
                                rows='10'
                                value={courseprogram} 
                                onChange={(e)=>{return setcourseprogram(e.target.value)}}
                                style={{
                                    resize:'none',
                                    padding:'10px 20px 10px 20px',
                                    width:'100%',

 
                                }}

                            />

                        </SingleInput>


                        <div style={{width:'100%', display:'flex' }}>
                            <ButtonStyles 
                                onClick={()=>{
                                    if(!docType_id || !college_id || !docDate || !currentUser || !studentName){
                                    return toast.info('Preencha todas as informações para continuar')   
                                    }
                                    return sendInfo()
                                }}
                            >Salvar</ButtonStyles>
                        </div>

                    </Body_1>
                    
                }



                {isactive===2 &&
                    <>
                    <Body_1>
                        
                        <SingleInput>
                            <InputLabel >Evento</InputLabel>
                            <FormControl sx={{width:'300px'}}>
                                <InputLabel >Evento</InputLabel>
                                <Select
                                value={docType}
                                label="Evento"
                                onChange={(e)=>{
                                    console.log(e.target.value);
                                    setdocType(e.target.value); 

                                    var thisdoc = allevents.find(single=>single.id===e.target.value) 
                                    return setdocType_id(thisdoc.id)
                                }}
                                >
                                    {allevents && allevents.map((single,index)=>{
                                        if(single.id && single.eventname){
                                            return <MenuItem value={single.id} key={index}>{single.eventname}</MenuItem>
                                        }
                                        return null
                                    }).filter(Boolean)}
                                </Select>
                            </FormControl>
                        </SingleInput>

                        <SingleInput>
                            <InputLabel >Instituição</InputLabel>
                            <FormControl sx={{width:'300px'}}>
                                <InputLabel >Instituição</InputLabel>
                                <Select
                                // labelId="demo-simple-select-label"
                                // id="demo-simple-select"
                                value={college}
                                label="Instituição"
                                onChange={(e)=>{
                                    console.log(e.target.value);
                                    setcollege(e.target.value); 

                                    var thisdoc = allcolleges.find(single=>single.id===e.target.value) 
                                    if (thisdoc) {
                                        return setcollege_id(thisdoc.id)
                                    }
                                }}
                                >
                                    {allcolleges && allcolleges.map((single,index)=>{
                                        if(single.id && single.name){
                                            return <MenuItem value={single.id} key={index}>{single.name}</MenuItem>
                                        }
                                        return null
                                    }).filter(Boolean)}
                                </Select>
                            </FormControl>
                        </SingleInput>


                        <SingleInput >
                            <InputLabel >Anexe o arquivo para atualização em lotes</InputLabel>
                            <div style={{display:'flex',alignItems:'center',gap:'10px',}}>
                                <button style={{display:'flex',padding:'5px 10px 5px 10px',backgroundColor:'#ccc',border:'none',borderRadius:'5px',cursor:'pointer',margin:'0px'}}
                                    onClick={() => {
                                    selectFile({}, ({ source, name, size, file }) => {
                                        setisfileok(false)
                                        console.log({ source, name, size, file })
                                        var aux  = name
                                        var aux1 = aux.includes(".xlsx")
                                        if(!aux1){
                                            return toast.error('Insira um arquivo ".xlsx" para continuar')
                                        }
                                        setisfileok(true)
                                        console.log('is .xlsx');
                                    })
                                    }}
                                >
                                    Fazer upload
                                </button>

                                {file && isfileok ? (
                                    <span> Nome: {file.name} </span>
                                ) : (
                                    <span>Nenhum arquivo selecionado</span>
                                )}
                            </div>
                        </SingleInput>



                        <div style={{width:'100%', display:'flex' }}>
                            <ButtonStyles 
                                onClick={()=>{
                                    if(!docType_id || !college_id || !docDate || !currentUser || !studentName){
                                    return toast.info('Preencha todas as informações para continuar')   
                                    }
                                    return sendInfo()
                                }}
                            >Salvar</ButtonStyles>
                        </div>

                    </Body_1>
                    </>
                }


                {isactive===3 &&
                    <Body_1>

                        <SingleInput >
                            <InputLabel >Nome do evento</InputLabel>
                            <TextField label="Nome do evento" variant="outlined" sx={{width:'300px'}} value={eventname} onChange={(e)=>{return seteventname(e.target.value)}}/>
                        </SingleInput>

                        <SingleInput >
                            <InputLabel >Local do evento</InputLabel>
                            <TextField label="Local do evento" variant="outlined" sx={{width:'300px'}} value={eventlocation} onChange={(e)=>{return seteventlocation(e.target.value)}}/>
                        </SingleInput>

                        <SingleInput>
                            <InputLabel >Tipo de evento</InputLabel>
                            <FormControl sx={{width:'300px'}}>
                                <InputLabel >Tipo de evento</InputLabel>
                                <Select
                                // labelId="demo-simple-select-label"
                                // id="demo-simple-select"
                                value={eventtype_name}
                                label="Tipo de evento"
                                onChange={(e)=>{
                                    console.log(e.target.value);
                                    seteventtype_name(e.target.value); 

                                    var thisdoc = alleventType.find(single=>single.id===e.target.value) 
                                    if (thisdoc) {
                                        return seteventtype_id(thisdoc.id)
                                    }
                                }}
                                >
                                    {alleventType && alleventType.map((single,index)=>{
                                        if(single.id && single.name){
                                            return <MenuItem value={single.id} key={index}>{single.name}</MenuItem>
                                        }
                                        return null
                                    }).filter(Boolean)}
                                </Select>
                            </FormControl>
                        </SingleInput>


                        <div style={{width:'100%', display:'flex' }}>
                            <ButtonStyles 
                                onClick={()=>{
                                    if(!eventname || !eventlocation || !eventtype_id || !eventtype_name){
                                    return toast.info('Preencha todas as informações para continuar')   
                                    }
                                    return createnewevent()
                                }}
                            >Salvar</ButtonStyles>
                        </div>

                    </Body_1>
                }



                
            </Body>
        

        
        
        </Root>
    )
}