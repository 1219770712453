import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { TextField } from "@mui/material";
import { useState } from "react";
import show from '../../assets/icons/show.svg'
import hide from '../../assets/icons/hide.svg'
import API from "../../API_CONFIGS/redux/API";
import { loading } from "../../Loading";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { setUser, login } from "../../redux/authSlice";
import { useDispatch } from 'react-redux';



const Root = styled('div')({ 
    width:'100%',
    display:'flex',
    flexDirection:'column',
    
})


const Header = styled('div')({ 
    display:'flex',
    margin:'20px auto 20px auto',
    padding:'10px',
    fontSize:'18pt',
    fontWeight:'bold',
    color:'#000c7c',

    
})
const Form = styled('div')({ 
    minWidth:'300px',
    maxWidth:'400px',
    minHeight:'300px',
    border:'1px solid #000c7c',
    borderRadius:'5px',
    display:'flex',
    flexDirection:'column',
    margin:'20px auto 20px auto',
    padding:'20px',
    fontSize:'14pt',
    fontWeight:'bold',
    color:'#000c7c',
    gap:'20px',
    // alignItems:'center',
    justifyContent:'center',


    
})
const Form_Option = styled('div')({ 
    display:'flex',
    flexDirection:'column',
    justifyContent:'center',
    alignItems:'center',
    gap:'10px',
    
})
const Form_Option_title = styled('div')({ 
    
})

export default function (){
    const navigate = useNavigate()
    const dispatch = useDispatch();

    const [optionToShow,setOptionToShow]=useState(1)

    const [email,setemail]=useState('')
    const [emailOK,setemailOK]=useState(false)
    const [password,setpassword]=useState('')
    const [password2,setpassword2]=useState('')
    const [passwordOK,setpasswordOK]=useState(false)
    const [passwordOK2,setpasswordOK2]=useState(false)
    const [showpassword,setshowpassword]=useState(false)


    const handleemail = (e)=>{
        setemail(e.target.value)
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.(com|com.br)$/;
        if (emailRegex.test(e.target.value)) {
            return setemailOK(true)
        } 
        return setemailOK(false)
    }


    const handlepassword = (e)=>{
        setpassword(e.target.value)
        const passwordRegex = /^(?=(?:[^a-z]*[a-z]){3})(?=(?:[^A-Z]*[A-Z]){3})(?=(?:[^\d]*\d){1})(?=(?:[^\W_]*[\W_]){1})[a-zA-Z\d\W_]+$/;
        if (passwordRegex.test(e.target.value)) {
                return setpasswordOK(true)
            } 
                return setpasswordOK(false)
    }
    
    const handlepassword2 = (e)=>{
        setpassword2(e.target.value)
    }

    useEffect(()=>{
        if (password2 === password) {
            return setpasswordOK2(true)
        } 
            return setpasswordOK2(false)

    },[password,password2])


    const handleLogin = ()=>{
        loading(true)

        const data = {
            email:email,
            senha:password,
        }
        API.post('users/login',data)
        .then((res)=>{
            loading(false)
            dispatch(login(res.data.user));
            navigate('/administrativo')
            return toast.success(res.data.message?res.data.message:'Login efetuado')
        })
        .catch((error)=>{
            loading(false)
            localStorage.removeItem('user')
            console.log(error);
            toast.error(error.response.data.message?error.response.data.message:'Erro ao logar')
            return

        })
    }


    return (
        <Root>

        {optionToShow === 1 &&
        <>
            <Header>Digite seus dados de acesso:</Header>
            <Form>
                <Form_Option>
                    <Form_Option_title>Email</Form_Option_title>
                    <TextField onChange={handleemail}  value={email || ''} 
                            fullWidth
                            variant="outlined"
                            style={{height:'30px'}}
                            InputProps={{
                                style: { height: '100%' },
                            }}
                            error={email && !emailOK}

                    
                    />
                    {email && !emailOK &&
                        <p style={{fontSize:'12pt', color:'red', fontWeight:'normal', margin:'0px'}}>Email inválido.</p>
                    }

                </Form_Option>
                <Form_Option>
                    <Form_Option_title>Senha</Form_Option_title>
                    <div style={{ display: 'flex', gap: '0px', alignItems: 'center', justifyContent: 'center', width:'100%' }}>
                        <TextField
                            type={showpassword ? 'text' : 'password'}
                            onChange={handlepassword}
                            value={password || ''}
                            fullWidth
                            variant="outlined"
                            style={{height:'30px'}}
                            error={password && !passwordOK}
                            InputProps={{
                                endAdornment: (
                                    <img
                                    src={showpassword ? hide : show}
                                    alt={showpassword ? 'Hide Password' : 'Show Password'}
                                    width='20px'
                                    height='20px'
                                    onClick={()=>{return setshowpassword(!showpassword)}}
                                    style={{ cursor: 'pointer', position: 'relative', right: '0px', marginLeft:'10px' }}
                                    />
                                ),
                                style: { height: '100%' },
                            }}
                        />
                    </div>

                    <div style={{fontSize:'8pt', cursor:'pointer', textDecoration:'underline', margin:'0px 0px 0px auto'}} onClick={()=>{setOptionToShow(3)}}>Esqueci minha senha</div>
                    <div style={{fontSize:'8pt', cursor:'pointer', textDecoration:'underline', margin:'0px 0px 0px auto'}} onClick={()=>{setOptionToShow(2)}}>Não tenho conta</div>

                    {password && !passwordOK &&
                        <p style={{fontSize:'12pt', color:'red', fontWeight:'normal',margin:'0px', }}>Senha deve conter ao menos:<br/><li style={{margin:'0px 0px 0px 0px'}}>3 letras minúsculas;</li><br/><li style={{margin:'-25px 0px 0px 0px'}}>3 letras maiúsculas;</li><br/><li style={{margin:'-25px 0px 0px 0px'}}>1 caracter especial;</li><br/><li style={{margin:'-25px 0px 0px 0px'}}>1 número;</li></p>
                    }


                </Form_Option>
                <Form_Option>
                <button disabled={!emailOK || !passwordOK } onClick={handleLogin} style={{width:'200px', backgroundColor:'white' , padding:'5px 10px 5px 10px', display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center', cursor:'pointer', borderRadius:'5px', fontWeight:'bold' , transform: 'scale(1)', transition: 'transform 0.3s ease', cursor: !emailOK || !passwordOK  ? 'not-allowed' : 'pointer', }}
                    onMouseEnter={(e) => {
                        e.target.style.backgroundColor = '#000c7c';
                        e.target.style.color = 'white';
                        e.target.style.transform = 'scale(1.05)'; // Zoom in by 5%

                    }}
                    onMouseLeave={(e) => {
                        e.target.style.backgroundColor = '';
                        e.target.style.color = '';
                        e.target.style.transform = 'scale(1)'; // Reset the scale

                    }}

                >Entrar</button>

                </Form_Option>
            </Form>

        </>
        }

        {optionToShow ===2 &&
        <>
        
        <Header>Crie seu novo acesso:</Header>
            <Form>



            <h3 style={{fontSize:'10pt', color:'red'}}>OBS: Por segurança, Todos os acessos são fornecidos pela própria instituição. Contate o setor responsável, se precisar de ajuda.</h3>




                {/* <Form_Option>
                    <Form_Option_title>Email</Form_Option_title>
                    <TextField onChange={handleemail}  value={email || ''} 
                            fullWidth
                            variant="outlined"
                            style={{height:'30px'}}
                            InputProps={{
                                style: { height: '100%' },
                            }}
                            error={email && !emailOK}

                    
                    />
                    {email && !emailOK &&
                        <p style={{fontSize:'12pt', color:'red', fontWeight:'normal', margin:'0px'}}>Email inválido.</p>
                    }

                </Form_Option>
                <Form_Option>
                    <Form_Option_title>Senha</Form_Option_title>
                    <div style={{ display: 'flex', gap: '0px', alignItems: 'center', justifyContent: 'center', width:'100%' }}>
                        <TextField
                            type={showpassword ? 'text' : 'password'}
                            onChange={handlepassword}
                            value={password || ''}
                            fullWidth
                            variant="outlined"
                            style={{height:'30px'}}
                            error={password && !passwordOK}
                            InputProps={{
                                endAdornment: (
                                    <img
                                    src={showpassword ? hide : show}
                                    alt={showpassword ? 'Hide Password' : 'Show Password'}
                                    width='20px'
                                    height='20px'
                                    onClick={()=>{return setshowpassword(!showpassword)}}
                                    style={{ cursor: 'pointer', position: 'relative', right: '0px', marginLeft:'10px' }}
                                    />
                                ),
                                style: { height: '100%' },
                            }}
                        />
                    </div>
                    {password && !passwordOK &&
                        <p style={{fontSize:'12pt', color:'red', fontWeight:'normal',margin:'0px', }}>Senha deve conter ao menos:<br/><li style={{margin:'0px 0px 0px 0px'}}>3 letras minúsculas;</li><br/><li style={{margin:'-25px 0px 0px 0px'}}>3 letras maiúsculas;</li><br/><li style={{margin:'-25px 0px 0px 0px'}}>1 caracter especial;</li><br/><li style={{margin:'-25px 0px 0px 0px'}}>1 número;</li></p>
                    }

                </Form_Option>
                <Form_Option>
                    <Form_Option_title>Confirme a senha</Form_Option_title>
                    <div style={{ display: 'flex', gap: '0px', alignItems: 'center', justifyContent: 'center', width:'100%' }}>
                        <TextField
                            type={showpassword ? 'text' : 'password'}
                            onChange={handlepassword2}
                            value={password2 || ''}
                            fullWidth
                            variant="outlined"
                            style={{height:'30px'}}
                            error={password2 && !passwordOK2}
                            InputProps={{
                                endAdornment: (
                                    <img
                                    src={showpassword ? hide : show}
                                    alt={showpassword ? 'Hide Password' : 'Show Password'}
                                    width='20px'
                                    height='20px'
                                    onClick={()=>{return setshowpassword(!showpassword)}}
                                    style={{ cursor: 'pointer', position: 'relative', right: '0px', marginLeft:'10px' }}
                                    />
                                ),
                                style: { height: '100%' },
                            }}
                        />
                    </div>
                    {password2 && !passwordOK2 &&
                        <p style={{fontSize:'12pt', color:'red', fontWeight:'normal',margin:'0px', }}>As senhas devem ser iguais.</p>
                    }


                </Form_Option> */}

                <div style={{fontSize:'8pt', cursor:'pointer', textDecoration:'underline', margin:'0px 0px 0px auto'}} onClick={()=>{setOptionToShow(1)}}>Já tenho conta</div>

                <Form_Option>
                <button disabled={!emailOK || !passwordOK || !passwordOK2} onClick={handleLogin} style={{width:'200px', backgroundColor:'white' , padding:'5px 10px 5px 10px', display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center', cursor:'pointer', borderRadius:'5px', fontWeight:'bold' , transform: 'scale(1)', transition: 'transform 0.3s ease', cursor: !emailOK || !passwordOK || !passwordOK2 ? 'not-allowed' : 'pointer', }}
                    onMouseEnter={(e) => {
                        e.target.style.backgroundColor = '#000c7c';
                        e.target.style.color = 'white';
                        e.target.style.transform = 'scale(1.05)'; // Zoom in by 5%

                    }}
                    onMouseLeave={(e) => {
                        e.target.style.backgroundColor = '';
                        e.target.style.color = '';
                        e.target.style.transform = 'scale(1)'; // Reset the scale

                    }}

                >Cadastrar</button>

                </Form_Option>
            </Form>


        </>
        }

        {optionToShow ===3 &&
        <>
        
        <Header>Recuperar sua senha:</Header>
            <Form>

                <h3 style={{fontSize:'10pt', color:'red'}}>OBS: Por segurança, para resetar sua senha, contate o setor responsável.</h3>


                {/* <Form_Option>
                    <Form_Option_title>Email</Form_Option_title>
                    <TextField onChange={handleemail}  value={email || ''} 
                            fullWidth
                            variant="outlined"
                            style={{height:'30px'}}
                            InputProps={{
                                style: { height: '100%' },
                            }}
                            error={email && !emailOK}

                    
                    />
                    {email && !emailOK &&
                        <p style={{fontSize:'12pt', color:'red', fontWeight:'normal', margin:'0px'}}>Email inválido.</p>
                    }

                </Form_Option> */}

                <div style={{fontSize:'8pt', cursor:'pointer', textDecoration:'underline', margin:'0px 0px 0px auto'}} onClick={()=>{setOptionToShow(2)}}>Não tenho conta</div>

                <Form_Option>
                <button disabled={!emailOK || !passwordOK || !passwordOK2} onClick={handleLogin} style={{width:'200px', backgroundColor:'white' , padding:'5px 10px 5px 10px', display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center', cursor:'pointer', borderRadius:'5px', fontWeight:'bold' , transform: 'scale(1)', transition: 'transform 0.3s ease', cursor: !emailOK || !passwordOK || !passwordOK2 ? 'not-allowed' : 'pointer', }}
                    onMouseEnter={(e) => {
                        e.target.style.backgroundColor = '#000c7c';
                        e.target.style.color = 'white';
                        e.target.style.transform = 'scale(1.05)'; // Zoom in by 5%

                    }}
                    onMouseLeave={(e) => {
                        e.target.style.backgroundColor = '';
                        e.target.style.color = '';
                        e.target.style.transform = 'scale(1)'; // Reset the scale

                    }}

                >Recuperar senha</button>

                </Form_Option>
            </Form>


        </>
        }

        </Root>

    )



}
