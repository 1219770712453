// authSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: JSON.parse(localStorage.getItem('user')) || null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
      localStorage.setItem('user', JSON.stringify(state.user));

    },

    logout: (state) => {
      state.user = null;
      localStorage.removeItem('user');
      window.location = '/login';
    },

    login: (state, action) => {
        state.user = action.payload;
        localStorage.setItem('user', JSON.stringify(state.user));
    },
  },
});

export const { setUser , logout , login} = authSlice.actions;
export const selectUser = (state) => state.auth.user;
export default authSlice.reducer;
