import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import logobranca from'../../assets/images/logo-branca.png'
// import { useNavigate } from "react-router-dom";
import avatar from '../../assets/icons/avatar.svg'
import avatar_reverse from '../../assets/icons/avatar-reverse.svg'
import { loading } from "../../Loading";
import { toast } from "react-toastify";
import { useSelector } from 'react-redux';
import { logout } from "../../redux/authSlice";
import { useDispatch } from "react-redux";
import useWindowSize from '../Width'
import hamburguer_menu from '../../assets/icons/hamburger-menu.svg'
import { Sling as Hamburger } from 'hamburger-react'


const Root = styled('div')({
width:'calc(100vw - 10px)',
display:'flex',
gap:'10px',
backgroundColor:'#000c7c',
color:'white',
padding:'5px',
alignItems:'center',


})
const Root_mobile = styled('div')({
    width:'calc(100vw - 10px)',
    height:'60px',
    display:'flex',
    gap:'10px',
    backgroundColor:'#000c7c',
    color:'white',
    padding:'5px',
    alignItems:'center',
    zIndex:'99999999'

})

const Elements = styled('div')({
    display:'flex',
    width:'100%',
    margin:'auto auto auto 10px',
    gap:'20px',
    // padding:'20px',
    alignItems:'center',


})
const Element = styled('div')({
    display:'flex',
    padding:'5px 10px 5px 10px',
    border:'1px solid black',
    cursor:'pointer',
    color:'white',
    fontWeight:'bold',
    fontSize:'14pt',
    borderRadius:'5px',
    ':hover':{
        backgroundColor:'rgba(255, 255, 255)',
        color:'#000c7c',
    }

})
const Element_login = styled('div')({
    display:'flex',
    padding:'5px 10px 5px 10px',
    border:'1px solid black',
    minWidth:'100px',
    maxWidth:'350px',
    cursor:'pointer',
    backgroundColor:'rgba(255, 255, 255)',
    color:'#000c7c',
    fontSize:'12pt',
    borderRadius:'5px',
    margin:'auto 10px auto auto',
    gap:'10px',
    alignItems:'center',
    justifyContent:'center',

})

const Options = styled('div')({
    display:'flex',
    zIndex:'9999999999999999',
    flexDirection:'column',
    position:'fixed',
    minWidth:'200px',
    minHeight:'50px',
    backgroundColor:'rgba(0, 12, 124, 1)',
    padding:'10px',
    borderRadius:'5px',
    margin: '80px auto auto auto',  
    right: '10px', 
    color:'white',
        ':before': {
            content: '""',
            position: 'absolute', 
            top: '-14px', 
            left: '50%', 
            marginLeft: '-10px', 
            width: '0',
            height: '0',
            borderLeft: '15px solid transparent', 
            borderRight: '15px solid transparent', 
            borderBottom: '15px solid rgba(0, 12, 124, 1)', 
            },
})
const Option = styled('div')({
    display:'flex',
    width:'100%',
    height:'25px',
    justifyContent:'center',
    alignItems:'center',
    cursor:'pointer',
    fontSize:'12pt',
        ':hover':{
            fontWeight:'bold',
        }
})
const Option_user = styled('div')({
    display:'flex',
    width:'100%',
    height:'25px',
    justifyContent:'center',
    alignItems:'center',
    cursor:'pointer',
    fontSize:'12pt',
        ':hover':{
            fontWeight:'bold',
            color:'rgba(166, 255, 0, 1)',
        }
})
const Option_logout = styled('div')({
    display:'flex',
    width:'100%',
    height:'25px',
    justifyContent:'center',
    alignItems:'center',
    cursor:'pointer',
    fontSize:'12pt',
    margin:'0px auto 0px auto',
        ':hover':{
            fontWeight:'bold',
            color:'red',
        }
})




export default function(){
    const dispatch = useDispatch()
    const {width} = useWindowSize()

    const [route,setroute]=useState('')


    const currentUser = useSelector((state) => state.auth.user);


    const [openOptions,setOpenOptions]=useState(false)
    const [hamburguer,sethamburguer]=useState(false)



    useEffect(()=>{
        const path = (window.location.pathname).split('/')
        setroute(path[1])
    },[])   
    useEffect(()=>{
        console.log(width);
    },[width])   

    
    return (
        <>

            {width>600?
                <Root style={{width:width<500&&'100vw'}}>
                        <img src={logobranca} width='150px'  style={{cursor:'pointer', margin:'5px 40px 5px 20px' }} onClick={()=>{ window.location.href = '/'}} />
                    <Elements style={{flexDirection:width<500&&'column'}}>
                        { currentUser && currentUser.id &&
                            <>
                                <Element style={route==='contato'?{backgroundColor:'rgba(255, 255, 255)', color:'#000c7c', }:{}} 
                                    onClick={()=>{window.location.href = '/administrativo'}}
                                >Administrativo</Element>
                                <Element style={route==='contato'?{backgroundColor:'rgba(255, 255, 255)', color:'#000c7c', }:{}} 
                                    onClick={()=>{window.location.href = '/cadastro'}}
                                >Cadastros</Element>
                                <Element style={route==='listar'?{backgroundColor:'rgba(255, 255, 255)', color:'#000c7c', }:{}} 
                                    onClick={()=>{window.location.href = '/listar'}}
                                >Listar</Element>
                            </>
                        }

                        <Element style={route==='validar'?{backgroundColor:'rgba(255, 255, 255)', color:'#000c7c', }:{}} 
                            onClick={()=>{window.location.href = '/validar'}}
                        >Validar</Element>
                        <Element style={route==='contato'?{backgroundColor:'rgba(255, 255, 255)', color:'#000c7c', }:{}} 
                            onClick={() => { window.open('https://faculdadereboucas.com.br/ouvidoria/', '_blank'); }}
                        >Contato</Element>









                        <div style={{ display:'flex', margin:'auto 10px auto auto', justifyContent:'center'}}>



                         {currentUser ?



                         
                         <Element_login style={route==='login'?{backgroundColor:'rgba(255, 255, 255)', color:'#000c7c', }:{}} 
                            onClick={() => { setOpenOptions(!openOptions) }}
                         >
                            {route==='login'?
                                <img src={avatar_reverse}  width='30px'/>
                                :
                                <img src={avatar} width='30px' />

                            }
                            {currentUser?.nome}
                        </Element_login>
                        : 
                         <Element_login style={route==='login'?{backgroundColor:'rgba(255, 255, 255)', color:'#000c7c', }:{}} 
                         onClick={() => { window.location.href = '/login' }}
                         >
                                <img src={avatar} width='30px' />
                                Login administrativo
                        </Element_login>



                        }
                        {openOptions && currentUser && 

                            <>
                        <div style={{width:'100vw', height:'100vh', backgroundColor:'rgba(0,0,0,0)', display:'flex', position:'fixed',top:'0', left:'0', zIndex:'99999999999999'}}
                            onClick={()=>{setOpenOptions(false)}}
                        
                        ></div>

                            <Options>
                                <Option_user style={{display:'flex', gap:'10px', justifyContent:'left', alignItems:'center', margin:'20px auto 20px 5px',   }} onClick={()=>{toast.info("Em breve...")}} >
                                    <img src={avatar} width='50px' />
                                    <div >
                                        <div style={{fontSize:'12pt',}}>{currentUser?.nome}</div>
                                        <div style={{fontSize:'8pt',}}>{currentUser?.cargo}</div>
                                    </div>
                                </Option_user>
                                <div style={{width:'100%', height:'1px', backgroundColor:'#ccc', margin:'0px auto 20px auto',    }}/>
                                <Option onClick={()=>{toast.info("Em breve...")}}>Configurações</Option>
                                <Option onClick={()=>{toast.info("Em breve...")}}>Ajuda</Option>
                                <div style={{width:'100%', height:'1px', backgroundColor:'#ccc', margin:'20px auto 0px auto',    }}/>
                                <Option_logout onClick={()=>{ dispatch(logout())  }}  >Logout</Option_logout>

                            </Options>

                            </>
                        }
                    </div>

                    
                    
                    </Elements>
                </Root>

                :


                <Root_mobile 
                    style={{    }}
                >
                        <img src={logobranca} width='100px'  style={{cursor:'pointer', margin:'5px 40px 5px 20px' }} onClick={()=>{ window.location.href = '/'}} />
                        {/* <img src={hamburguer_menu} width='20px'  style={{cursor:'pointer', margin:'auto 10px auto auto' }} onClick={()=>{ setOpenOptions(true)}} /> */}

                        <div style={{cursor:'pointer', margin:'auto 10px auto auto' ,   }} >
                            <Hamburger   size={20} color="#fff" onToggle={()=>{setOpenOptions(!openOptions)}} rounded  duration={1} toggled={openOptions}    />
                        </div>

                        {openOptions &&

                            <div style={{   }}>
                                <div style={{width:'100%', height:'100%', backgroundColor:'rgba(0,0,0,0.4)', display:'flex', position:'absolute',top:'62px', left:'0px', zIndex:'9'}}
                                    onClick={()=>{setOpenOptions(false)}}
                                
                                ></div>
                                
                                
                                <div style={{
                                    width:'100%',
                                    backgroundColor:'#000c7c',
                                    padding:'10px',
                                    display:'flex',
                                    flexDirection:'column',
                                    gap:'10px',
                                    alignItems:'center',
                                    justifyContent:'center',
                                    position:'absolute',
                                    top:'62px',
                                    left:'0px',
                                    zIndex:'9999',



                                }}>


                        {!currentUser && 

                        <>
                                <Element style={route==='validar'?{backgroundColor:'rgba(255, 255, 255)', color:'#000c7c',fontSize:'10pt' }:{fontSize:'10pt' }} 
                                    onClick={()=>{window.location.href = '/validar'}}
                                >Validar</Element>
                                <Element style={route==='contato'?{backgroundColor:'rgba(255, 255, 255)', color:'#000c7c', fontSize:'10pt' }:{fontSize:'10pt' }} 
                                    onClick={() => { window.open('https://faculdadereboucas.com.br/ouvidoria/', '_blank'); }}
                                >Contato</Element>

                        



                        

                            <Element_login style={route==='login'?{backgroundColor:'rgba(255, 255, 255)', color:'#000c7c',fontSize:'10pt', margin:'auto' }:{fontSize:'10pt',  margin:'auto'    }} 
                            onClick={() => { window.location.href = '/login' }}
                            >
                                <img src={avatar} width='20px' />
                                Login administrativo
                            </Element_login>
                        </>
                        }




                                {currentUser &&
                                    <>
                                        
                                        <Option_user style={{display:'flex', gap:'10px', justifyContent:'left', alignItems:'center', margin:'20px auto 20px 5px',   }} >
                                            <img src={avatar} width='50px' />
                                            <div>
                                                <div style={{fontSize:'12pt',}}>{currentUser?.nome}</div>
                                                <div style={{fontSize:'8pt',}}>{currentUser?.cargo}</div>
                                            </div>
                                        </Option_user>
                                        <div style={{width:'100%', height:'1px', backgroundColor:'#ccc', margin:'0px auto 20px auto',    }}/>



                                        <Option  onClick={()=>{window.location.href = '/validar'}} >Validar</Option>
                                        <Option onClick={() => { window.open('https://faculdadereboucas.com.br/ouvidoria/', '_blank'); }} >Contato</Option>
                                        <Option onClick={()=>{toast.info("Em breve...")}}>Configurações</Option>
                                        <Option onClick={()=>{toast.info("Em breve...")}}>Ajuda</Option>



                                        <div style={{width:'100%', height:'1px', backgroundColor:'#ccc', margin:'20px auto 0px auto',    }}/>
                                        <Option_logout onClick={()=>{ dispatch(logout())  }}  >Logout</Option_logout>

                                    </>
                                }




                                </div>
                            </div>
                        }
                        


                </Root_mobile>



            }
</>
    )
}















