import logo from './logo.svg';
import './App.css';
import { styled } from '@mui/system';
import Header from './components/header/Header';
import Validator from './pages/validator/Validator';
import Listar from './pages/Listar/Listar';
import Home from './pages/Home/Home';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import styles
import { useState, useEffect } from 'react';
import { loading } from './Loading';
import Login from './pages/Login/Login';
// import Adm from './pages/adm/Adm';
import Certificate_register from './pages/certificate_register/Certificate_register';





import {
  Routes,
  Route,
  BrowserRouter,
  redirect,
  Navigate 
} from "react-router-dom";



const Root = styled('div')({
  backgroundColor:'white',
  width:'100vw',
  height:'100vh',
})

function App() {

  useEffect(() => {
    loading(true)
    setTimeout(() => {
      loading(false)
    }, 1000);
  }, []); 


  return (
    <Root>
      <Header/>

      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Navigate to="/validar" />} />
          <Route path='/listar' element={<Listar/>}/>
          <Route path='/validar' element={<Validator/>}/>
          <Route path='/validar/:id' element={<Validator/>}/>
          <Route path='/login' element={<Login/>}/>
          <Route path='/cadastro' element={<Certificate_register/>}/>
          <Route path='/cadastro/:id' element={<Certificate_register/>}/>

        </Routes>
      </BrowserRouter>



      <ToastContainer 
        position="bottom-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        // rtl={false}
        // pauseOnFocusLoss
        // draggable
        pauseOnHover={false}
        theme="light"
        style={{margin:'auto auto 10px auto'}}

      
      />

      </Root>

  );
}

export default App;
