    import React, { useEffect, useState, useRef } from "react";
import styled from "@emotion/styled";
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import search from '../../assets/icons/search.svg';
import Stack from '@mui/material/Stack';
import QRCode from "react-qr-code";
import * as htmlToImage from "html-to-image";
    import { toast } from "react-toastify";
import { json } from "react-router-dom";
import { loading } from "../../Loading";
// import Width from '../../components/Width'
import useWindowSize from "../../components/Width";
import API from "../../API_CONFIGS/redux/API"; 


const Root = styled('div')({ 
    width:'100vw',
    display:'flex',
    flexDirection:'column',
    margin:'auto',
    justifyContent:'center'
    
})
const Header = styled('div')({
    // width:'100%',
    display:'flex',
    flexDirection:'column',
    alignItems:'center',
    justifyContent:'center',
    fontSize:'15pt',
    fontWeight:'bold',
    padding:'10px',
    textAlign:'center',

})
const Body = styled('div')({
    // width:'100%',
    display:'flex',
    gap:'20px',
    padding:'20px',
    justifyContent:'center',
    alignItems:'center',

    flexDirection:'column',

})
const Results = styled('div')({
    maxWidth:'80vw',
    display:'flex',
    // gap:'30px',
    // padding:'20px',
    justifyContent:'center',
    // alignItems:'center',
    flexDirection:'column',

    // overflow:'auto',

    // backgroundColor:'red'

})
const ShowDoc_Desktop = styled('div')({
    display:'flex', 
    flexDirection:'column',
    gap:'10px',
    padding:'20px',
    maxWidth:'500px',
    justifyContent:'center',
    // alignItems:'center',
    // backgroundColor:'red',

    border:'1px solid #000c7c',
    borderRadius:'5px',


})
const SingleInformation = styled('div')({
    display:'flex', 
    // gap:'10px',
    // width:'20%',
    fontSize:'12pt',
    fontWeight:'bold',
    // margin:'0px 20px 0px 0px'



})


function showDate(dateString){
        const day = new Date(dateString).getDate()
        const month = new Date(dateString).getMonth() + 1
        const year = new Date(dateString).getFullYear()
        return `${day}/${month}/${year}`
}

function showCpf(string){
    var aux = string
    aux = aux.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "\$1.\$2.\$3-\$4")
    return aux
}


export default function (){

    const {width} = useWindowSize()

    const [docID,setdocID]=useState('')
    const [showResult,setshowResult]=useState(false)
    const [blocksearch,setblocksearch]=useState(false)
    const [alldocs,setalldocs] = useState([
        {
            id:'abc',
            aluno:'aluno nome 1',
            documento_nome:'Curso de abc',
            carga_horaria:123,
            tipo:'curso',
            data_emissão:'10/12/2016'
        },
    ])
    const [docs,setDocs] = useState({})
    
    const qrCodeContainerRef = useRef(null);

    useEffect(()=>{

    },[docID])

    const handleSearch = ()=>{
        loading(true);
        setshowResult(false)

        const path = window.location.pathname.split('/')
        if((!docID ) && (!path[2] ) && window.location.pathname === '/validar' ){
            loading(false)
            return toast.info('Preencha os dados para buscar')
        }




        API.get(`/validation/${docID}`)
        .then((res)=>{
            toast.success('Dados encontrados')
            setblocksearch(true)
            loading(false);
            setDocs(res.data.document)
            return setshowResult(true)        
        })
        .catch((error)=>{
            loading(false);
            toast.error('Nenhum dado encontrado')
            return setshowResult(false)        
        })




                // const aux = alldocs.filter((singleDoc) =>singleDoc.id === docID);
                    // if(aux.length>0){
                        // setDocs(aux)
                        // toast.success('Dados encontrados')
                        // setblocksearch(true)
                        // loading(false);
                        // return setshowResult(true)        
                    // }
        // loading(false);
        // toast.error('Nenhum dado encontrado')
        // return setshowResult(false)        
    }


    useEffect(()=>{
        const path = window.location.pathname.split('/')
        if (path[2] && !showResult && !docID ) {
            setdocID(path[2])
            setblocksearch(true)
        }
        else if(window.location.pathname !== '/validar'){
            handleSearch()
        }

    },[docID])


    const handleDownload  = () => {
        if( docID ){


            if (qrCodeContainerRef.current) {
                console.log(qrCodeContainerRef);
                const container = qrCodeContainerRef.current;
          
                // Use htmlToImage to capture the container as an image
                htmlToImage
                  .toPng(container)
                  .then(function (dataUrl) {
                    // Create a downloadable link
                    const downloadLink = document.createElement("a");
                    downloadLink.href = dataUrl;
                    downloadLink.download = `${docID}.png`;
                    return downloadLink.click();
                  })
                  .catch(function (error) {
                    return console.error("Error generating QR code image", error);
                  });
              }
                                                    
            
            }
        return
      };
      

      

    return(
        <Root   style={{    }}   >
            <Header >
                Validador de documentos emitidos pela Faculdade Rebouças de Campina Grande
            </Header>

            <Body >

            <div style={{display:'flex', gap:'10px'}}>
            <div style={{width:width>500&&'400px', }}>
                <InputLabel >Número / ID do documento</InputLabel>
                <TextField disabled={blocksearch} value={docID} onChange={(e)=>{setshowResult(false);setdocID(e.target.value)}}   fullWidth />
            </div>
            </div>

            <div style={{ margin:'auto 0px auto 0px',  display:'flex', flexDirection:width>500?"row":'column', gap:'10px'  }}>
            <Button variant="contained" disabled={blocksearch} endIcon={<img  src={search}  width='20px'  />}     onClick={()=>{return handleSearch() }} >
                Pesquisar
            </Button>
            {blocksearch &&
            <Button variant="contained"   onClick={()=>{loading(); return window.location.href = '/validar' }} >
                Resetar
            </Button>
            }

            </div>

            {showResult&&
<Results>
  {docs &&

    <>

        <ShowDoc_Desktop style={{margin:'60px auto 60px auto'}}>
                {/* <div style={{display:'flex', flexDirection:'column',  gap:width>500?'5px':'15px', }}  > */}
                    <div style={{display:'flex', flexDirection:width>500?"row":'column', gap:'10px', width:'100%',       }}  >
                        <SingleInformation style={{width:width<700?'100%':'30%',margin:'0px'}}>Nome do Aluno: </SingleInformation>
                        <p style={{fontSize:'12pt', width:width<700?'100%':'100%', margin:'0px'}}>{docs.name}</p>
                    </div>
                    <div style={{width:'100%', height:'1px', backgroundColor:'#ccc', margin:'10px 0px 10px 0px'}}  />
                {/* </div> */}
                {/* <div style={{display:'flex', flexDirection:'column',  gap:width>500?'5px':'15px', }}  > */}
                    <div style={{display:'flex', flexDirection:width>500?"row":'column', gap:'10px', width:'100%',       }}  >
                        <SingleInformation style={{width:width<700?'100%':'30%',margin:'0px'}}>CPF: </SingleInformation>
                        <p style={{fontSize:'12pt', width:width<700?'100%':'100%', margin:'0px'}}>{showCpf(docs.cpf)}</p>
                    </div>
                    <div style={{width:'100%', height:'1px', backgroundColor:'#ccc', margin:'10px 0px 10px 0px'}}  />
                {/* </div> */}
                {/* <div style={{display:'flex', flexDirection:'column',  gap:width>500?'5px':'15px', }}  > */}
                    <div style={{display:'flex', flexDirection:width>500?"row":'column', gap:'10px', width:'100%',       }}  >
                        <SingleInformation style={{width:width<700?'100%':'30%',margin:'0px'}}>Curso: </SingleInformation>
                        <p style={{fontSize:'12pt', width:width<700?'100%':'100%', margin:'0px'}}>{docs.coursetitle}</p>
                    </div>
                    <div style={{width:'100%', height:'1px', backgroundColor:'#ccc', margin:'10px 0px 10px 0px'}}  />
                {/* </div> */}
                {/* <div style={{display:'flex', flexDirection:'column',  gap:width>500?'5px':'15px', }}  > */}
                    <div style={{display:'flex', flexDirection:width>500?"row":'column', gap:'10px', width:'100%',       }}  >
                        <SingleInformation style={{width:width<700?'100%':'30%',margin:'0px'}}>Programa do curso: </SingleInformation>
                        <p style={{fontSize:'12pt', width:width<700?'100%':'100%', margin:'0px'}}>{docs.courseprogram}</p>
                    </div>
                    <div style={{width:'100%', height:'1px', backgroundColor:'#ccc', margin:'10px 0px 10px 0px'}}  />
                {/* </div> */}
                {/* <div style={{display:'flex', flexDirection:'column',  gap:width>500?'5px':'15px', }}  > */}
                    <div style={{display:'flex', flexDirection:width>500?"row":'column', gap:'10px', width:'100%',       }}  >
                        <SingleInformation style={{width:width<700?'100%':'30%',margin:'0px'}}>Carga horária: </SingleInformation>
                        <p style={{fontSize:'12pt', width:width<700?'100%':'100%', margin:'0px'}}>{docs.workload}</p>
                    </div>
                    <div style={{width:'100%', height:'1px', backgroundColor:'#ccc', margin:'10px 0px 10px 0px'}}  />
                {/* </div> */}
                {/* <div style={{display:'flex', flexDirection:'column',  gap:width>500?'5px':'15px', }}  > */}
                    <div style={{display:'flex', flexDirection:width>500?"row":'column', gap:'10px', width:'100%',       }}  >
                        <SingleInformation style={{width:width<700?'100%':'30%',margin:'0px'}}>Data da emissão: </SingleInformation>
                        <p style={{fontSize:'12pt', width:width<700?'100%':'100%', margin:'0px'}}>{showDate(docs.issuedAt)}</p>
                    </div>
                {/* </div> */}
        </ShowDoc_Desktop>

        { docID && qrCodeContainerRef &&

            <div style={{  padding: "20px", display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center',gap:'20px',    }}>
                <div ref={qrCodeContainerRef} style={{display:'flex',   border: "1px solid #ccc",   backgroundColor: "#fff", margin:'auto !important', width:width<700?"100px":'300px', height:width<700?"100px":'300px' , justifyContent:'center', alignItems:'center' }} >
                    <QRCode value={`https://validator.faculdadereboucas.com.br/validar/${docID}`} style={{width:'90%', cursor:'pointer' }} onClick={handleDownload} />
                </div>
            

                <button onClick={handleDownload} style={{backgroundColor:'white' , padding:'5px 10px 5px 10px', display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center', cursor:'pointer', borderRadius:'5px', fontWeight:'bold' , transform: 'scale(1)', transition: 'transform 0.3s ease', }}
                onMouseEnter={(e) => {
                    e.target.style.backgroundColor = '#000c7c';
                    e.target.style.color = 'white';
                    e.target.style.transform = 'scale(1.05)'; // Zoom in by 5%
                }}
                onMouseLeave={(e) => {
                    e.target.style.backgroundColor = '';
                    e.target.style.color = '';
                    e.target.style.transform = 'scale(1)'; // Reset the scale
                }}
                >Download QR Code</button>
            </div>
        }

    </>
    
    }
</Results>
            }


            </Body>

        </Root>
    )
}